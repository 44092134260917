<template>
  <b-card>

    <!-- form -->
    <validation-observer ref="modelsCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-overlay
              :show="loading"
              rounded
              opacity="0.5"
              spinner-small
              spinner-variant="primary"
            >
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="ShieldIcon"
                    width="24"
                    height="24"
                  />
                  <h2>Update Firewall List</h2>
                </div>
                <div>
                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="IP Address:"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="address"
                        vid="address"
                      >
                        <b-form-ip
                          :errors="errors"
                          placeholder="IP Address"
                          autocomplete="off"
                          :onchange="(value) => form.address = value"
                          :model="form.address"
                          inputs-id="address"
                          :is-full-available="!isAddressRange || loading"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <p
                      v-if="isAddressRange || loading"
                      style="margin-top: 35px;"
                    >-</p>
                    <b-form-group
                      v-if="isAddressRange || loading"
                      label="IP Address (End)"
                      label-class="label-class"
                      class="neighbouring-form-group input-with-checkbox"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="IP Address Range"
                        vid="address2"
                        rules="max:255"
                        class="validator-with-button"
                      >
                        <b-form-ip
                          :errors="errors"
                          placeholder="Enter IP Address"
                          autocomplete="off"
                          :onchange="(value) => address2 = value"
                          :model="address2"
                          inputs-id="address2"
                          class="select-with-checkbox"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <div
                      class="neighbouring-form-container"
                    >
                      <p class="rangeMargin">
                        IP Address Range:
                      </p>
                      <b-form-checkbox
                        v-model="isAddressRange"
                        name="client_forwarding"
                        switch
                        class="rangeMargin"
                        inline
                      />
                    </div>
                    <div
                      v-if="!isAddressRange"
                      :style="{ flex: 1 }"
                    />
                  </div>

                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Address List Name:"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Address List Name"
                        vid="list"
                        rules="required"
                      >
                        <v-select
                          v-model="form.list"
                          :options="options"
                          :searchable="true"
                          label="list"
                          @search="handleListChange"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Comment"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Comment"
                        rules="max:255"
                        vid="comment"
                      >
                        <b-form-input
                          v-model="form.comment"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter the comment"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Enabled:"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Enabled:"
                        vid="enabled"
                      >
                        <b-form-checkbox
                          v-model="form.enabled"
                          name="is_hidden"
                          switch
                          inline
                          class="mt-50"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                </div>
              </div>
            </b-overlay>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right buttons-container__left"
            >

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                ok-variant="secondary"
                @click.prevent="goBack"
              >
                Cancel
              </b-button>
              <b-button-request
                text="Update"
                :request="validationForm"
              />
            </b-col>
          </b-col></b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import { required, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from '../../axios-resolver'
import BFormIp from '../../../../components/BFormIP.vue'
import BButtonRequest from '../../../../components/BButtonRequest.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButtonRequest,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormIp,
    BForm,
    BFormCheckbox,
    BRow,
    vSelect,
    BCol,
    BButton,
    BOverlay,
  },
  data() {
    return {
      siteId: this.$route.params.id,
      loading: true,
      required,
      max,
      isEditForm: false,
      isAddressRange: false,
      address2: '',
      form: {
        address: '',
        list: '',
        comment: '',
        enabled: true,
      },
      listName: [],
      options: [],
    }
  },
  watch: {
    isAddressRange(value) {
      if (!value) {
        this.address2 = ''
      } else {
        const [newAddress] = this.form.address.split('/')
        this.form.address = newAddress
      }
    },
  },
  destroyed() {
    document.querySelector('.content-header-title__extra-info').innerHTML = ''
  },
  created() {
    this.setSiteName(this.$route.params.id)
    this.fetchItem()
    this.getOptions()
  },
  methods: {
    async setSiteName(id) {
      try {
        if (id) {
          const pageTitle = document.querySelector('.content-header-title__extra-info')
          const { data: { data } } = await axios.get(`/site/${id}/show`)

          const link = document.createElement('a')
          link.href = `/site/${id}/hardware/index`
          link.innerHTML = `(${data.name})`

          pageTitle.innerHTML = ''
          pageTitle.appendChild(link)
        }

        this.isAccessPointUnlicensed = this.summary.isAccessPointUnlicensed // do when the API will be ready
      } catch (error) {
        console.log('error', error)
      }
    },
    async fetchItem() {
      try {
        const { data: { data } } = await axios.get(`/site/${this.$route.params.id}/firewall/address-lists/${this.$route.params.listId}`)

        this.form = {
          ...data,
          enabled: !data.disabled,
          address: '',
        }

        this.isAddressRange = data.address.includes('-')

        if (this.isAddressRange) {
          const [address1, address2] = data.address.split('-')
          console.log('[address1, address2]', [address1, address2])

          this.form.address = address1
          this.address2 = address2
        } else {
          this.form.address = data.address
        }

        this.loading = false
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async getOptions() {
      try {
        const { data: { data } } = await axios.get(`/site/${this.$route.params.id}/firewall/address-lists/options`)
        this.listName = data
        this.options = data
      } catch (error) {
        console.log(error)
      }
    },
    handleListChange(value) {
      if (!value) {
        return
      }
      this.form.list = value

      if (this.options.length === this.listName.length) {
        this.options.unshift(value)
      } else {
        this.options = this.options.map((item, index) => {
          if (index === 0) {
            return value
          }
          return item
        })
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    async validationForm() {
      const valid = await this.$refs.modelsCreate.validate()

      if (valid) {
        try {
          this.disabledSaveButton = true
          const payload = {
            address: this.isAddressRange ? `${this.form.address}-${this.address2}` : this.form.address,
            list: this.form.list,
            comment: this.form.comment,
            disabled: !this.form.enabled,
          }
          await axios.put(`/site/${this.$route.params.id}/firewall/address-lists/${this.form.id}`, payload)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.$router.go(-1)
        } catch (error) {
          const { status } = error.response

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.modelsCreate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
