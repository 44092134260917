var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"modelsCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"","opacity":"0.5","spinner-small":"","spinner-variant":"primary"}},[_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"ShieldIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("Update Firewall List")])],1),_c('div',[_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"IP Address:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"address","vid":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"IP Address","autocomplete":"off","onchange":function (value) { return _vm.form.address = value; },"model":_vm.form.address,"inputs-id":"address","is-full-available":!_vm.isAddressRange || _vm.loading}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.isAddressRange || _vm.loading)?_c('p',{staticStyle:{"margin-top":"35px"}},[_vm._v("-")]):_vm._e(),(_vm.isAddressRange || _vm.loading)?_c('b-form-group',{staticClass:"neighbouring-form-group input-with-checkbox",attrs:{"label":"IP Address (End)","label-class":"label-class"}},[_c('validation-provider',{staticClass:"validator-with-button",attrs:{"name":"IP Address Range","vid":"address2","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{staticClass:"select-with-checkbox",attrs:{"errors":errors,"placeholder":"Enter IP Address","autocomplete":"off","onchange":function (value) { return _vm.address2 = value; },"model":_vm.address2,"inputs-id":"address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3877010246)})],1):_vm._e(),_c('div',{staticClass:"neighbouring-form-container"},[_c('p',{staticClass:"rangeMargin"},[_vm._v(" IP Address Range: ")]),_c('b-form-checkbox',{staticClass:"rangeMargin",attrs:{"name":"client_forwarding","switch":"","inline":""},model:{value:(_vm.isAddressRange),callback:function ($$v) {_vm.isAddressRange=$$v},expression:"isAddressRange"}})],1),(!_vm.isAddressRange)?_c('div',{style:({ flex: 1 })}):_vm._e()],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Address List Name:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address List Name","vid":"list","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.options,"searchable":true,"label":"list"},on:{"search":_vm.handleListChange},model:{value:(_vm.form.list),callback:function ($$v) {_vm.$set(_vm.form, "list", $$v)},expression:"form.list"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Comment","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Comment","rules":"max:255","vid":"comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter the comment","autocomplete":"off"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Enabled:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Enabled:","vid":"enabled"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"mt-50",attrs:{"name":"is_hidden","switch":"","inline":""},model:{value:(_vm.form.enabled),callback:function ($$v) {_vm.$set(_vm.form, "enabled", $$v)},expression:"form.enabled"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])])]),_c('b-col',{staticClass:"text-right buttons-container__left",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","ok-variant":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('b-button-request',{attrs:{"text":"Update","request":_vm.validationForm}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }